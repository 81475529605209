import CertifiedFair from '../views/Certifications/CertfiedFair.jsx';
import ContactPage from '../views/ContactUs/ContactPage.jsx';
import HomePage from '../views/HomePage/HomePage.jsx';
import HowtoPlay from '../views/HowToPlay/HowtoPlay.jsx';
import Features from '../views/Features/Features.jsx';
import Web3Integration from '../views/Web3/Web3Integration.jsx';
import B2BPage from '../views/B2B/B2BPage';
import TermsPage from '../views/TermsPage/TermsPage';
import PrivacyPage from '../views/PrivacyPage/PrivacyPage.jsx';
import SopoPlus from '../views/SopoPlus/SopoPlus.jsx';
const routes = [
    {
        path: '/',
        component: <HomePage />,
        exact: true,
    },
    {
        path: '/howtoplay',
        component: <HowtoPlay />,
        exact: true,
    },
    {
        path: '/web3integration',
        component: <Web3Integration />,
        exact: true,
    },
    {
        path: '/certifiedfair',
        component: <CertifiedFair />,
        exact: true,
    },
    {
        path: '/socialfeatures',
        component: <Features />,
        exact: true,
    },
    {
        path: '/b2b-solution',
        component: <B2BPage />,
        exact: true,
    },
    {
        path: '/terms-of-service',
        component: <TermsPage />,
        exact: true,
    },
    {
        path: '/privacy-policy',
        component: <PrivacyPage />,
        exact: true,
    },
    {
        path: '/contact-us',
        component: <ContactPage />,
        exact: true,
    },
    // {
    //     path: '/sopo',
    //     component: <SopoPlus />,
    //     exact: true,
    // },
];

export default routes;

import './App.css';
import routes from './routes/Routes.js';
import {
  HashRouter,
  Routes, // instead of "Switch"
  Route,
} from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
function App() {
  return (
    <div className="App">
      <HashRouter basename="/">
        <Navbar></Navbar>
        <div className='main-app-container'>
        <Routes>
          {routes.map(({ path, component, exact }) => (
            <Route key={path} path={path} element={component} exact={exact} />
          ))}
        </Routes>
        </div>
        <Footer />
      </HashRouter>
    </div>
  );
}

export default App;
import './FourColumnLayout.css';
import FourColumn from './FourColumn';
 
const FourColumnLayout = ({ data, certifClick }) => {
  return (
    <div className="container">
      <div className="row">
        {data.map((how) => (
          <FourColumn
            content={how}
            certifClick={certifClick}
          />
        ))}
      </div>
    </div>
  );
};

export default FourColumnLayout;

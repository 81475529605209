import React, { useEffect, useState } from 'react';
import PageBackground from '../../components/Layouts/PageBackground';
import ContactModal from './ContactModal';
import './ContactPage.css';

const ContactPage = () => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [subject, setSubject] = useState('');
  const [details, setDetails] = useState('');
  const [error, setError] = useState(false);
  const [modalText, setmodalText] = useState('');
  const [modalIsOpen, setmodalIsOpen] = useState(false);
  const [mobile, setmobile] = useState(false);
  
  useEffect(() => {
    if (window.innerWidth < 628) {
      setmobile(true);
    }
  }, []);

  const handleChange = (value, type) => {
    switch (type) {
      case 'name':
        setName(value);
        break;
      case 'email':
        setEmail(value);
        break;
      case 'subject':
        setSubject(value);
        break;
      case 'details':
        setDetails(value);
        break;
      default:
        break;
    }
    setError(false);
  };
  const handlerSubmit = (e) => {
    e.preventDefault();
    let isValidFrontend;
    isValidFrontend = handleValidation();
    console.log('isValidFrontend', isValidFrontend);
    if (isValidFrontend) {
      window.grecaptcha.ready(function () {
        window.grecaptcha
          .execute('6LdLayQhAAAAAGbPoXJaUm9ejKCIGBEQQSV1rSkb', {
            action: 'submit',
          })
          .then(function (token) {
            // Add your logic to submit to your backend server here.
            document.getElementById('recaptcha-responseContact').value =
              token;

            document.querySelector("#contactForm").submit();
          });
      });
    } else {
      return false;
    }
  };
  const handleValidation = () => {
    const re =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (
      re.test(email) &&
      details.length > 0 &&
      subject.length > 0 &&
      name.length > 0
    ) {
      return true;
    } else {
      setError(true)
      alert(!re.test(email) ? 'Enter a valid email!' : 'Fields are required!');
      return false
    }

  };
  useEffect(() => {
    if (window.location.hash === '#/contact-us?successEmailSent') {
      // alert('Email sent successfully!');
      setmodalText('Email sent successfully!')
      setmodalIsOpen(true);
    }
    else if (window.location.hash === '#/contact-us?errorEmailSent') {
      // alert('Error in email sending!')
      setmodalText('Email send failed!')
      setmodalIsOpen(true);
    }
  }, [])


  return (
    <>
      <PageBackground title="CONTACT US">
        <h3 className='contact-page-sub-title gradient-box-title'>
          Please enter your information below and a representative will get back
          to you as soon as possible.
        </h3>
        <div className={'contact-uoter-box'}>
          <form className='form-holder' id='contactForm' method='post' action='contact.php'>
            <textarea
              className='is-hidden'
              type='hidden'
              id='recaptcha-responseContact'
              name='g-recaptcha-response'
            ></textarea>
            <div className='input-holder'>
              <label htmlFor='name'>Your Name</label>
              <input
                name='name'
                onChange={(e) => handleChange(e.target.value, 'name')}
              ></input>
            </div>
            <div className='input-holder'>
              <label htmlFor='email'>
                {mobile ? "SOPO Email address" : "Email address associated with your SOPO account"}
              </label>
              <input
                name='email'
                onChange={(e) => handleChange(e.target.value, 'email')}
              ></input>
            </div>
            <div className='input-holder'>
              <label htmlFor='subject'>Subject</label>
              <input
                name='subject'
                onChange={(e) => handleChange(e.target.value, 'subject')}
              ></input>
            </div>
            <div className='input-holder'>
              <label htmlFor='details'>Details</label>
              <textarea
                name='details'
                onChange={(e) => handleChange(e.target.value, 'details')}
              ></textarea>
            </div>
            <div>
              <button
                id='contactSendButton'
                className='contact-submit-button '
                type='submit'
                name='submitBtn'
                disabled={error ? true : false}
                onClick={handlerSubmit}
              >
                SUBMIT
              </button>
            </div>
          </form>
        </div>
      </PageBackground>
      <ContactModal isOpen={modalIsOpen}>
        <div className='modal-element-holder gradient-red-bg'>
          <p>{modalText}</p>
          <button
            className='contact-submit-button'
            style={{marginTop: '20px'}}
            onClick={() => { setmodalIsOpen(false) }}>Okay</button>
        </div>
      </ContactModal>
    </>
  );
};

export default ContactPage;

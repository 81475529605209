import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './Navbar.css';
import DiamondSopoLogo from '../assets/icons/sopo-logo.png';
import Hamburger from '../assets/icons/hamburger.png';
import { useNavigate } from 'react-router-dom';
import SopoLogo from '../assets/icons/sopoLogo.png'
import AppStore from '../assets/icons/app-store.png';
import GooglePlay from '../assets/icons/google-play.png';

export default function Navbar() {
    const navigates = useNavigate();
    const [toggleMenu, setToggleMenu] = useState(false);

    const toggleNav = () => {
        setToggleMenu(!toggleMenu);
    };
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    useEffect(() => {
        const changeWidth = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', changeWidth);
        return () => {
            window.removeEventListener('resize', changeWidth);
        };
    }, []);

    const navigate = (param) => {
        setToggleMenu(false);
        navigates(param);
    };

    const [headerColor, setHeaderColor] = useState("transparent")
    const listenScrollEvent = () => {
        window.scrollY > 0
          ? setHeaderColor("#001736")
          : setHeaderColor("transparent")
      }
    useEffect(() => {
      window.addEventListener("scroll", listenScrollEvent)
    })

    const [storeButtons, setStoreButtons] = useState(false);
    const location = useLocation();
    useEffect(() => {
        if (location.pathname !== '/') setStoreButtons(true);
        else setStoreButtons(false);
    })

    useEffect(() => {
      window.scrollTo(0, 0);
      setToggleMenu(false);
    }, [location]);

    const DesktopNavbar = () => {
        return (
            <>
                <div className="left-align-div">
                    <ul className="list">
                        <li className="items navbar-logo">
                            <img src={DiamondSopoLogo} alt="diamond-logo"/>
                        </li>
                        <li className="items" onClick={() => navigate('/')}>
                            HOME
                        </li>
                        <li
                            className="items"
                            onClick={() => navigate('howtoplay')}
                        >
                            HOW TO PLAY
                        </li>
                        <li
                            className="items"
                            onClick={() => navigate('web3integration')}
                        >
                            WEB3
                        </li>
                        <li
                            className="items"
                            onClick={() => navigate('socialfeatures')}
                        >
                            FEATURES
                        </li>
                        <li
                            className="items"
                            onClick={() => navigate('certifiedfair')}
                        >
                            CERTIFICATIONS
                        </li>
                        <li 
                            className="items" 
                            onClick={() => navigate('contact-us')}
                        >
                            CONTACT
                        </li>
                    </ul>
                    {storeButtons && 
                        (<div className="navbar-store-icons">
                            <img onClick={()=>window.open("https://apps.apple.com/us/app/sopo-social-poker/id1441432679")} src={AppStore} alt="store-icons"/>
                            <img onClick={()=>window.open("https://play.google.com/store/apps/details?id=software.odysseus.sopo&hl=en&gl=US")} src={GooglePlay} alt="google-play-icons"/>
                        </div>)}
                </div>
            </>
        );
    };
    const MobileNavbar = () => {
        return (
            <>
                <div className="mobile-top-bar">
                    <div className="m-li-div">
                        <li className="items-mobile">
                            <img
                                style={{ maxWidth: '45px' }}
                                src={DiamondSopoLogo}
                                onClick={() => navigate('/')}
                                alt="sopoLogo"
                            />
                        </li>
                    </div>
                    <div className="m-li-div">
                        <img src={SopoLogo} alt="sopoLogo"/>
                    </div>
                    <div className="m-li-div j-start">
                        <li className="items-mobile" onClick={toggleNav}>
                            <img src={Hamburger} style={{ maxWidth: '31px' }} alt="hamburgerMenu"/>
                        </li>
                    </div>
                </div>
                <div
                        className={` ${
                            toggleMenu ? 'toggleMenu' : 'mobile-nav-container'
                        } `}
                    >
                        <ul>
                            <li
                                className="items py-10"
                                onClick={() => navigate('/')}
                            >
                                HOME
                            </li>
                            <li
                                className="items py-10"
                                onClick={() => navigate('howtoplay')}
                            >
                                HOW TO PLAY
                            </li>
                            <li
                                className="items py-10"
                                onClick={() => navigate('web3integration')}
                            >
                                WEB3
                            </li>
                            <li
                                className="items py-10"
                                onClick={() => navigate('socialfeatures')}
                            >
                                FEATURES
                            </li>
                            <li
                                className="items py-10"
                                onClick={() => navigate('certifiedfair')}
                            >
                                CERTIFICATIONS
                            </li>
                            <li
                                className="items py-10"
                                onClick={() => navigate('contact-us')}
                            >
                                CONTACT
                            </li>
                            <div className="navbar-store-icons">
                                <img onClick={()=>window.open("https://apps.apple.com/us/app/sopo-social-poker/id1441432679")} src={AppStore} alt="store-icons"/>
                                <img onClick={()=>window.open("https://play.google.com/store/apps/details?id=software.odysseus.sopo&hl=en&gl=US")} src={GooglePlay} alt="google-play-icons"/>
                            </div>
                        </ul>
                    </div>
           
            </>
        );
    };
    return (
        <>
            {screenWidth > 1025 ? (
                <nav style={{background: headerColor}}>
                    <div className="wide-container d-flex j-center nav-inner">
                        <DesktopNavbar />
                    </div>
                </nav>
            ) : (
                <MobileNavbar />
            )}
        </>
    );
}
